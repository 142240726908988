import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BillingCodeNodeField: any;
  ClientNodeField: any;
  ComponentNodeField: any;
  ContextNodeField: any;
  ContractNodeField: any;
  ContractRateCardNodeField: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  IdentityNodeField: any;
  InternalProjectNodeField: any;
  /**
   * Positive Decimal scalar implementation.
   * Should be used in places where value must be positive.
   */
  PositiveDecimal: any;
  ProductNodeField: any;
  ProductObjectiveNodeField: any;
  ProfitCentreNodeField: any;
  ProjectRoleAssignmentNodeField: any;
  RateCardNodeField: any;
  RateCardRoleNodeField: any;
  RepositoryNodeField: any;
  RoleNodeField: any;
  StringMoney: any;
  UserNodeField: any;
};

export type AddComponentToBillingCodeInput = {
  component: Scalars['ComponentNodeField'];
  instance: Scalars['BillingCodeNodeField'];
};

export type AddComponentToProductInput = {
  component: Scalars['ComponentNodeField'];
  instance: Scalars['ProductNodeField'];
};

export type AddRoleToRateCardInput = {
  instance: Scalars['RateCardNodeField'];
  role: Scalars['RoleNodeField'];
};

export type BillingCodeComponentsInput = {
  components: Array<InputMaybe<Scalars['ComponentNodeField']>>;
  instance: Scalars['BillingCodeNodeField'];
};

export type BillingCodeInput = {
  components: Array<InputMaybe<Scalars['ComponentNodeField']>>;
  inactive: Scalars['Boolean'];
  instance?: InputMaybe<Scalars['BillingCodeNodeField']>;
  internalProject: Scalars['InternalProjectNodeField'];
  productObjective: Scalars['ProductObjectiveNodeField'];
  totalSpent?: InputMaybe<MoneyInput>;
};

export type BillingCodeInputPart = {
  components?: InputMaybe<Array<InputMaybe<Scalars['ComponentNodeField']>>>;
  instance?: InputMaybe<Scalars['BillingCodeNodeField']>;
  productObjective: Scalars['ProductObjectiveNodeField'];
};

export type BillingCodeNode = Node & {
  billingCodes?: Maybe<Array<BillingCodeNode>>;
  compactId: Scalars['String'];
  components: Array<ComponentNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  inactive: Scalars['Boolean'];
  internalProject?: Maybe<InternalProjectNode>;
  productObjective?: Maybe<ProductObjectiveNode>;
  realId: Scalars['Int'];
  tagId?: Maybe<Scalars['String']>;
  tagLabel?: Maybe<Scalars['String']>;
  totalSpent?: Maybe<Money>;
};

export type BillingCodeNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingCodeNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BillingCodeNode` and its cursor. */
export type BillingCodeNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BillingCodeNode>;
};

export type ClientInput = {
  inactive: Scalars['Boolean'];
  instance?: InputMaybe<Scalars['ClientNodeField']>;
  name: Scalars['String'];
};

export type ClientNode = Node & {
  compactId: Scalars['String'];
  contracts: Array<ContractNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  inactive: Scalars['Boolean'];
  name: Scalars['String'];
  realId: Scalars['Int'];
  totalBudget?: Maybe<Money>;
};

export type ClientNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ClientNode` and its cursor. */
export type ClientNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ClientNode>;
};

export type ComponentBillingCodeInput = {
  instance?: InputMaybe<Scalars['BillingCodeNodeField']>;
  productObjective?: InputMaybe<Scalars['ProductObjectiveNodeField']>;
};

export type ComponentInput = {
  billingCodes?: InputMaybe<Array<InputMaybe<ComponentBillingCodeInput>>>;
  inactive: Scalars['Boolean'];
  instance?: InputMaybe<Scalars['ComponentNodeField']>;
  name: Scalars['String'];
  product?: InputMaybe<Scalars['ProductNodeField']>;
  repository: RepositoryInput;
};

export type ComponentNode = Node & {
  compactId: Scalars['String'];
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  inactive: Scalars['Boolean'];
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<ProductNode>>>;
  realId: Scalars['Int'];
  repository?: Maybe<RepositoryNode>;
};

export type ComponentNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ComponentNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ComponentNode` and its cursor. */
export type ComponentNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ComponentNode>;
};

export type ContentTypeNode = Node & {
  appLabel: Scalars['String'];
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  model: Scalars['String'];
  realId: Scalars['Int'];
};

export type ContextNode = Node & {
  compactId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  realId: Scalars['Int'];
};

export type ContextNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContextNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContextNode` and its cursor. */
export type ContextNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ContextNode>;
};

export type ContractInput = {
  accountManager: Scalars['UserNodeField'];
  budget?: InputMaybe<MoneyInput>;
  client: Scalars['ClientNodeField'];
  completed?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  documentUrl?: InputMaybe<Scalars['String']>;
  entity: Scalars['ProfitCentreNodeField'];
  instance?: InputMaybe<Scalars['ContractNodeField']>;
  name: Scalars['String'];
  projects: Array<InputMaybe<ProjectInputPart>>;
  signed?: InputMaybe<Scalars['Date']>;
  submitted?: InputMaybe<Scalars['Date']>;
  validUntil?: InputMaybe<Scalars['Date']>;
};

export type ContractNode = Node & {
  accountManager: UserNode;
  budget?: Maybe<Money>;
  client: ClientNode;
  compactId: Scalars['String'];
  completed?: Maybe<Scalars['Date']>;
  contractRateCards: Array<ContractRateCardNode>;
  created?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  documentUrl?: Maybe<Scalars['String']>;
  entity: EntityNode;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  projects: Array<InternalProjectNode>;
  realId: Scalars['Int'];
  signed?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Date']>;
  validUntil?: Maybe<Scalars['Date']>;
};

export type ContractNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContractNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContractNode` and its cursor. */
export type ContractNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ContractNode>;
};

export type ContractRateCardInput = {
  contract: Scalars['ContractNodeField'];
  dateFrom: Scalars['Date'];
  instance?: InputMaybe<Scalars['ContractRateCardNodeField']>;
  rateCard: Scalars['RateCardNodeField'];
};

export type ContractRateCardNode = Node & {
  compactId: Scalars['String'];
  contract?: Maybe<ContractNode>;
  dateFrom: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  rateCard: RateCardNode;
  realId: Scalars['Int'];
};

export type Currency = {
  /** A ISO-421 3-letter currency. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  code: Scalars['String'];
  /** A human readable name, e.g. US Dollar */
  name: Scalars['String'];
  /** A ISO-421 numeric code. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  numeric: Scalars['String'];
  /** The currency's prefix, e.g. $ for USD */
  prefix: Scalars['String'];
  /** The currency's symbol, e.g. € for EUR */
  suffix: Scalars['String'];
  /** The currency's symbol, e.g. $ for USD */
  symbol: Scalars['String'];
};

export type DeleteProjectRoleAssignment = {
  data?: Maybe<ProjectRoleAssignmentNode>;
};

export type DeleteRoleAssignmentInput = {
  instance: Scalars['ProjectRoleAssignmentNodeField'];
};

export type EntityNode = Node & {
  compactId: Scalars['String'];
  contractSet: ContractNodeConnection;
  entity?: Maybe<ProfitCentreNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  layer: Scalars['String'];
  manager?: Maybe<UserNode>;
  managerRoleName: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<ProfitCentreNode>;
  profitcentreSet: EntityNodeConnection;
  realId: Scalars['Int'];
  region?: Maybe<ProfitCentreNode>;
  regions: Array<RegionNode>;
  userSet: UserNodeConnection;
  xeroTrackingCategory?: Maybe<Scalars['String']>;
};

export type EntityNodeContractSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type EntityNodeProfitcentreSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type EntityNodeUserSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type EntityNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EntityNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EntityNode` and its cursor. */
export type EntityNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<EntityNode>;
};

export type GroupNode = Node & {
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<PermissionNode>>>;
  realId: Scalars['Int'];
};

export type HubNode = Node & {
  compactId: Scalars['String'];
  contractSet: ContractNodeConnection;
  entity?: Maybe<ProfitCentreNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  layer: Scalars['String'];
  manager?: Maybe<UserNode>;
  managerRoleName: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<ProfitCentreNode>;
  profitcentreSet: EntityNodeConnection;
  realId: Scalars['Int'];
  region?: Maybe<ProfitCentreNode>;
  userSet: UserNodeConnection;
  xeroTrackingCategory?: Maybe<Scalars['String']>;
};

export type HubNodeContractSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type HubNodeProfitcentreSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type HubNodeUserSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type IdentityInput = {
  context: Scalars['ContextNodeField'];
  entity: Scalars['UserNodeField'];
  instance?: InputMaybe<Scalars['IdentityNodeField']>;
  primaryIdentifier: Scalars['String'];
};

export type IdentityInputPart = {
  context: Scalars['ContextNodeField'];
  instance?: InputMaybe<Scalars['IdentityNodeField']>;
  primaryIdentifier: Scalars['String'];
};

export type IdentityNode = Node & {
  compactId: Scalars['String'];
  context?: Maybe<ContextNode>;
  entity?: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  primaryIdentifier?: Maybe<Scalars['String']>;
  realId: Scalars['Int'];
};

export type InternalProjectNode = Node & {
  /** Account Manager Approval means commitment(from the Region) to pay the hub for the work */
  accountManagerApproved: Scalars['Boolean'];
  billingCodes: Array<BillingCodeNode>;
  /** GST Inclusive. This is the limit of what the hub across all project billing codes. If it's not a fixed_price contract,  the objective is to spend all of it.If it's a T&M, the objective is to spend as little as possible. Either way, spending more than this will erode Hub Profits. */
  budget?: Maybe<Money>;
  compactId: Scalars['String'];
  /** Checking this will hide the project from the UI.It's sort-of like a soft-delete */
  completed: Scalars['Boolean'];
  /** The Contract that provides funding for this project. */
  contract: ContractNode;
  description: Scalars['String'];
  /** When work on the project should/did end. Used in burn-rate calculations. If null, the assumption is that the project ends when the contract ends. */
  endDate?: Maybe<Scalars['Date']>;
  /** Setting this true means our billing plan is to invoice the client each month regardless of time spent on billing codes of this project. If true, please explain how this should be billed in the project description! */
  fixedPrice: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** The manager of a project is responsible for the membership of the team and the dollars spent. They may or may not be the same as the delivery manager. They may not even be part of the delivery team. */
  manager?: Maybe<UserNode>;
  /** Choose a name that will make sense in the context of the Contract. I.e. it makes sense to break down the contract delivery as this collection of named projects. Often there will be only one project under the contract. In that case, choose a name that will make sense to the team. */
  name: Scalars['String'];
  /** If checked, it means permission has been granted to keep working on this project even after the parent contract has been completed. For example, if a client pays-out a contract at the end of financial year, with the understanding that we will finish the work in the new financial year. */
  overburn: Scalars['Boolean'];
  peopleAssignments: Array<ProjectRoleAssignmentNode>;
  /** The thing that will benefit from this project. It's also a social context for the team, if there are multiple projects on working on the same product then the people should all feel like one team. */
  product: ProductNode;
  realId: Scalars['Int'];
  /** When work on the project should/did start. Used in burn-rate calculations. If null, the assumption is that the project starts when the contract starts. */
  startDate?: Maybe<Scalars['Date']>;
  status: ProjectStatusEnum;
  /** If checked, it means permission has been granted to work on this project even if the parent contract has not yet been signed. */
  workAtRisk: Scalars['Boolean'];
};

export type InternalProjectNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InternalProjectNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InternalProjectNode` and its cursor. */
export type InternalProjectNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<InternalProjectNode>;
};

export type Money = {
  /** The numerical amount. */
  amount: Scalars['Float'];
  /** The string version of the numerical amount. */
  amountStr?: Maybe<Scalars['String']>;
  asString?: Maybe<Scalars['StringMoney']>;
  currency: Scalars['String'];
  currencyDetail?: Maybe<Currency>;
  formatAmount?: Maybe<Scalars['String']>;
};

export type MoneyFormatAmountArgs = {
  decimals?: InputMaybe<Scalars['Int']>;
};

export type MoneyInput = {
  /** Amount of money. */
  amount: Scalars['PositiveDecimal'];
  /** Currency code. */
  currency: Scalars['String'];
};

export type Mutation = {
  addComponentToBillingCode?: Maybe<BillingCodeNode>;
  addComponentToProduct?: Maybe<ProductNode>;
  addRoleToRateCard?: Maybe<RateCardNode>;
  billingCode?: Maybe<UpsertBillingCode>;
  billingCodeComponents?: Maybe<UpdateBillingCodeComponent>;
  client?: Maybe<UpsertClient>;
  component?: Maybe<UpsertComponent>;
  contract?: Maybe<UpsertContract>;
  contractRateCard?: Maybe<UpsertContractRateCard>;
  deleteProjectRoleAssignment?: Maybe<DeleteProjectRoleAssignment>;
  identity?: Maybe<UpsertIdentity>;
  product?: Maybe<UpsertProduct>;
  profitCentre?: Maybe<UpsertProfitCentre>;
  project?: Maybe<UpsertProject>;
  projectRoleAssignment?: Maybe<UpsertProjectRoleAssignment>;
  rateCard?: Maybe<UpsertRateCard>;
  refreshToken?: Maybe<Refresh>;
  removeComponentFromBillingCode?: Maybe<BillingCodeNode>;
  removeComponentFromProduct?: Maybe<ProductNode>;
  removeRoleFromRateCard?: Maybe<RateCardNode>;
  revokeToken?: Maybe<Revoke>;
  role?: Maybe<UpsertRole>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  user?: Maybe<UpsertUser>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAddComponentToBillingCodeArgs = {
  data: AddComponentToBillingCodeInput;
};

export type MutationAddComponentToProductArgs = {
  data: AddComponentToProductInput;
};

export type MutationAddRoleToRateCardArgs = {
  data: AddRoleToRateCardInput;
};

export type MutationBillingCodeArgs = {
  data: BillingCodeInput;
};

export type MutationBillingCodeComponentsArgs = {
  data: BillingCodeComponentsInput;
};

export type MutationClientArgs = {
  data: ClientInput;
};

export type MutationComponentArgs = {
  data: ComponentInput;
};

export type MutationContractArgs = {
  data: ContractInput;
};

export type MutationContractRateCardArgs = {
  data: ContractRateCardInput;
};

export type MutationDeleteProjectRoleAssignmentArgs = {
  data: DeleteRoleAssignmentInput;
};

export type MutationIdentityArgs = {
  data: IdentityInput;
};

export type MutationProductArgs = {
  data: ProductInput;
};

export type MutationProfitCentreArgs = {
  data: ProfitCentreInput;
};

export type MutationProjectArgs = {
  data: ProjectInput;
};

export type MutationProjectRoleAssignmentArgs = {
  data: ProjectRoleAssignmentInput;
};

export type MutationRateCardArgs = {
  data: RateCardInput;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type MutationRemoveComponentFromBillingCodeArgs = {
  data: RemoveComponentFromBillingCodeInput;
};

export type MutationRemoveComponentFromProductArgs = {
  data: RemoveComponentFromProductInput;
};

export type MutationRemoveRoleFromRateCardArgs = {
  data: RemoveRoleFromRateCardInput;
};

export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type MutationRoleArgs = {
  data: RoleInput;
};

export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationUserArgs = {
  data: UserInput;
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type PageCursor = {
  cursor?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
};

export type PageCursors = {
  around?: Maybe<Array<Maybe<PageCursor>>>;
  first?: Maybe<PageCursor>;
  last?: Maybe<PageCursor>;
  next?: Maybe<PageCursor>;
  previous?: Maybe<PageCursor>;
  totalPages?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PermissionNode = Node & {
  codename: Scalars['String'];
  compactId: Scalars['String'];
  contentType?: Maybe<ContentTypeNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  realId: Scalars['Int'];
};

export type ProductInput = {
  client: Scalars['ClientNodeField'];
  description?: InputMaybe<Scalars['String']>;
  inactive: Scalars['Boolean'];
  instance?: InputMaybe<Scalars['ProductNodeField']>;
  name: Scalars['String'];
};

export type ProductNode = Node & {
  compactId: Scalars['String'];
  components: Array<ComponentNode>;
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  inactive: Scalars['Boolean'];
  name: Scalars['String'];
  owner: ClientNode;
  productObjectives: Array<ProductObjectiveNode>;
  realId: Scalars['Int'];
};

export type ProductNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProductNode` and its cursor. */
export type ProductNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProductNode>;
};

export type ProductObjectiveNode = Node & {
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  inactive: Scalars['Boolean'];
  longName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  realId: Scalars['Int'];
  shortName: Scalars['String'];
};

export type ProductObjectiveNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductObjectiveNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProductObjectiveNode` and its cursor. */
export type ProductObjectiveNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProductObjectiveNode>;
};

export type ProfitCentreInput = {
  instance?: InputMaybe<Scalars['ProfitCentreNodeField']>;
  manager: Scalars['UserNodeField'];
  name: Scalars['String'];
  parent?: InputMaybe<Scalars['ProfitCentreNodeField']>;
};

export enum ProfitCentreLayer {
  Entity = 'ENTITY',
  Hub = 'HUB',
  Region = 'REGION',
}

export type ProfitCentreNode = Node & {
  compactId: Scalars['String'];
  entity?: Maybe<ProfitCentreNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  layer: Scalars['String'];
  manager?: Maybe<UserNode>;
  managerRoleName: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<ProfitCentreNode>;
  realId: Scalars['Int'];
  region?: Maybe<ProfitCentreNode>;
};

export type ProfitCentreNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfitCentreNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ProfitCentreNode` and its cursor. */
export type ProfitCentreNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProfitCentreNode>;
};

export type ProjectInput = {
  accountManagerApproved?: InputMaybe<Scalars['Boolean']>;
  billingCodes?: InputMaybe<Array<InputMaybe<BillingCodeInputPart>>>;
  budget?: InputMaybe<MoneyInput>;
  completed?: InputMaybe<Scalars['Boolean']>;
  contract: Scalars['ContractNodeField'];
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  fixedPrice?: InputMaybe<Scalars['Boolean']>;
  instance?: InputMaybe<Scalars['InternalProjectNodeField']>;
  manager?: InputMaybe<Scalars['UserNodeField']>;
  name: Scalars['String'];
  overburn?: InputMaybe<Scalars['Boolean']>;
  peopleAssignments?: InputMaybe<Array<InputMaybe<ProjectRoleAssignmentInputPart>>>;
  product: Scalars['ProductNodeField'];
  startDate: Scalars['Date'];
  workAtRisk?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectInputPart = {
  billingCodes?: InputMaybe<Array<InputMaybe<BillingCodeInputPart>>>;
  instance: Scalars['InternalProjectNodeField'];
};

export type ProjectRoleAssignmentInput = {
  instance?: InputMaybe<Scalars['ProjectRoleAssignmentNodeField']>;
  person: Scalars['UserNodeField'];
  project: Scalars['InternalProjectNodeField'];
  role: Scalars['RoleNodeField'];
};

export type ProjectRoleAssignmentInputPart = {
  instance?: InputMaybe<Scalars['ProjectRoleAssignmentNodeField']>;
  person: Scalars['UserNodeField'];
  role: Scalars['RoleNodeField'];
};

export type ProjectRoleAssignmentNode = Node & {
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  person?: Maybe<UserNode>;
  project?: Maybe<InternalProjectNode>;
  realId: Scalars['Int'];
  role?: Maybe<RoleNode>;
};

/** An enumeration. */
export enum ProjectStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Overdue = 'OVERDUE',
  Upcoming = 'UPCOMING',
}

export type Query = {
  billingCode?: Maybe<BillingCodeNode>;
  billingCodes?: Maybe<BillingCodeNodeConnection>;
  client?: Maybe<ClientNode>;
  clients?: Maybe<ClientNodeConnection>;
  component?: Maybe<ComponentNode>;
  components?: Maybe<ComponentNodeConnection>;
  contexts?: Maybe<ContextNodeConnection>;
  contract?: Maybe<ContractNode>;
  contracts?: Maybe<ContractNodeConnection>;
  entities: Array<EntityNode>;
  me?: Maybe<UserNode>;
  product?: Maybe<ProductNode>;
  productObjectives?: Maybe<ProductObjectiveNodeConnection>;
  products?: Maybe<ProductNodeConnection>;
  profitCentre?: Maybe<ProfitCentreNode>;
  profitCentres?: Maybe<ProfitCentreNodeConnection>;
  project?: Maybe<InternalProjectNode>;
  projects?: Maybe<InternalProjectNodeConnection>;
  rateCard?: Maybe<RateCardNode>;
  rateCards?: Maybe<RateCardNodeConnection>;
  regions?: Maybe<ProfitCentreNodeConnection>;
  role?: Maybe<RoleNode>;
  roles?: Maybe<RoleNodeConnection>;
  user?: Maybe<UserNode>;
  users?: Maybe<UserNodeConnection>;
};

export type QueryBillingCodeArgs = {
  gId?: InputMaybe<Scalars['BillingCodeNodeField']>;
};

export type QueryBillingCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryClientArgs = {
  gId?: InputMaybe<Scalars['ClientNodeField']>;
};

export type QueryClientsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contractEntityId?: InputMaybe<Scalars['String']>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryComponentArgs = {
  gId?: InputMaybe<Scalars['ComponentNodeField']>;
};

export type QueryComponentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryContextsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryContractArgs = {
  gId?: InputMaybe<Scalars['ContractNodeField']>;
};

export type QueryContractsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  entityId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryMeArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type QueryProductArgs = {
  gId?: InputMaybe<Scalars['ProductNodeField']>;
};

export type QueryProductObjectivesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProfitCentreArgs = {
  gId?: InputMaybe<Scalars['ProfitCentreNodeField']>;
};

export type QueryProfitCentresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  types?: InputMaybe<Array<InputMaybe<ProfitCentreLayer>>>;
};

export type QueryProjectArgs = {
  gId?: InputMaybe<Scalars['InternalProjectNodeField']>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contractId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  needDate?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRateCardArgs = {
  gId?: InputMaybe<Scalars['RateCardNodeField']>;
};

export type QueryRateCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRegionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRoleArgs = {
  gId?: InputMaybe<Scalars['RoleNodeField']>;
};

export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUserArgs = {
  gId?: InputMaybe<Scalars['UserNodeField']>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['String']>;
  hubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RateCardInput = {
  contract?: InputMaybe<Scalars['ContractNodeField']>;
  instance?: InputMaybe<Scalars['RateCardNodeField']>;
  rateCardRoles?: InputMaybe<Array<InputMaybe<RateCardRoleInput>>>;
  title: Scalars['String'];
};

export type RateCardNode = Node & {
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  rateCardRoles: Array<RateCardRoleNode>;
  realId: Scalars['Int'];
  title: Scalars['String'];
};

export type RateCardNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RateCardNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RateCardNode` and its cursor. */
export type RateCardNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<RateCardNode>;
};

export type RateCardRoleInput = {
  instance?: InputMaybe<Scalars['RateCardRoleNodeField']>;
  price?: InputMaybe<MoneyInput>;
  role: Scalars['RoleNodeField'];
  unit?: InputMaybe<RateCardRoleUnit>;
};

export type RateCardRoleNode = Node & {
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  price?: Maybe<Money>;
  realId: Scalars['Int'];
  role: RoleNode;
  unit?: Maybe<RateCardRoleUnit>;
};

/** An enumeration. */
export enum RateCardRoleUnit {
  Daily = 'daily',
  Hourly = 'hourly',
}

export type Refresh = {
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RegionNode = Node & {
  compactId: Scalars['String'];
  contractSet: ContractNodeConnection;
  entity?: Maybe<ProfitCentreNode>;
  hubs: Array<HubNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  layer: Scalars['String'];
  manager?: Maybe<UserNode>;
  managerRoleName: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<ProfitCentreNode>;
  profitcentreSet: EntityNodeConnection;
  realId: Scalars['Int'];
  region?: Maybe<ProfitCentreNode>;
  userSet: UserNodeConnection;
  xeroTrackingCategory?: Maybe<Scalars['String']>;
};

export type RegionNodeContractSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RegionNodeProfitcentreSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RegionNodeUserSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RemoveComponentFromBillingCodeInput = {
  component: Scalars['ComponentNodeField'];
  instance: Scalars['BillingCodeNodeField'];
};

export type RemoveComponentFromProductInput = {
  component: Scalars['ComponentNodeField'];
  instance: Scalars['ProductNodeField'];
};

export type RemoveRoleFromRateCardInput = {
  instance: Scalars['RateCardNodeField'];
  role: Scalars['RoleNodeField'];
};

export type RepositoryInput = {
  instance?: InputMaybe<Scalars['RepositoryNodeField']>;
  url: Scalars['String'];
};

export type RepositoryNode = Node & {
  compactId: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  realId: Scalars['Int'];
  url: Scalars['String'];
};

export type Revoke = {
  revoked: Scalars['Int'];
};

export type RoleInput = {
  description?: InputMaybe<Scalars['String']>;
  instance?: InputMaybe<Scalars['RoleNodeField']>;
  name: Scalars['String'];
};

export type RoleNode = Node & {
  compactId: Scalars['String'];
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  people: Array<UserNode>;
  peopleAssignments: Array<ProjectRoleAssignmentNode>;
  projects: Array<InternalProjectNode>;
  realId: Scalars['Int'];
};

export type RoleNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RoleNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RoleNode` and its cursor. */
export type RoleNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<RoleNode>;
};

export type UpdateBillingCodeComponent = {
  data?: Maybe<BillingCodeNode>;
};

export type UpsertBillingCode = {
  data?: Maybe<BillingCodeNode>;
};

export type UpsertClient = {
  data?: Maybe<ClientNode>;
};

export type UpsertComponent = {
  data?: Maybe<ComponentNode>;
};

export type UpsertContract = {
  data?: Maybe<ContractNode>;
};

export type UpsertContractRateCard = {
  data?: Maybe<ContractRateCardNode>;
};

export type UpsertIdentity = {
  data?: Maybe<IdentityNode>;
};

export type UpsertProduct = {
  data?: Maybe<ProductNode>;
};

export type UpsertProfitCentre = {
  data?: Maybe<ProfitCentreNode>;
};

export type UpsertProject = {
  data?: Maybe<InternalProjectNode>;
};

export type UpsertProjectRoleAssignment = {
  data?: Maybe<ProjectRoleAssignmentNode>;
};

export type UpsertRateCard = {
  data?: Maybe<RateCardNode>;
};

export type UpsertRole = {
  data?: Maybe<RoleNode>;
};

export type UpsertUser = {
  data?: Maybe<UserNode>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  hub: Scalars['ProfitCentreNodeField'];
  identities?: InputMaybe<Array<InputMaybe<IdentityInputPart>>>;
  instance?: InputMaybe<Scalars['UserNodeField']>;
  name: Scalars['String'];
  salaryHoursPerFortnight?: InputMaybe<Scalars['Decimal']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserNode = Node & {
  compactId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups?: Maybe<Array<Maybe<GroupNode>>>;
  hub?: Maybe<ProfitCentreNode>;
  /** The ID of the object */
  id: Scalars['ID'];
  identities: Array<IdentityNode>;
  inactive: Scalars['Boolean'];
  isRobot: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  name: Scalars['String'];
  projectAssignments: Array<ProjectRoleAssignmentNode>;
  projectCount?: Maybe<Scalars['Int']>;
  realId: Scalars['Int'];
  roles?: Maybe<Array<Maybe<RoleNode>>>;
  salaryHoursPerFortnight?: Maybe<Scalars['Decimal']>;
  userPermissions?: Maybe<Array<Maybe<PermissionNode>>>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type UserNodeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  pageCursors?: Maybe<PageCursors>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type Verify = {
  payload: Scalars['GenericScalar'];
};

export type DeleteProjectRoleAssignmentMutationVariables = Exact<{
  instance: Scalars['ProjectRoleAssignmentNodeField'];
}>;

export type DeleteProjectRoleAssignmentMutation = {
  deleteProjectRoleAssignment?: { data?: { id: string } | null } | null;
};

export type AddComponentToBillingCodeMutationVariables = Exact<{
  instance: Scalars['BillingCodeNodeField'];
  component: Scalars['ComponentNodeField'];
}>;

export type AddComponentToBillingCodeMutation = {
  addComponentToBillingCode?: {
    id: string;
    inactive: boolean;
    realId: number;
    tagId?: string | null;
    tagLabel?: string | null;
    components: Array<{ id: string; name: string }>;
  } | null;
};

export type AddComponentToProductMutationVariables = Exact<{
  data: AddComponentToProductInput;
}>;

export type AddComponentToProductMutation = {
  addComponentToProduct?: {
    components: Array<{
      id: string;
      realId: number;
      compactId: string;
      name: string;
      description: string;
      inactive: boolean;
      repository?: { id: string; realId: number; compactId: string; url: string } | null;
    }>;
  } | null;
};

export type AddRoleToRateCardMutationVariables = Exact<{
  data: AddRoleToRateCardInput;
}>;

export type AddRoleToRateCardMutation = {
  addRoleToRateCard?: {
    realId: number;
    id: string;
    rateCardRoles: Array<{
      role: { compactId: string; description: string; id: string; name: string; realId: number };
    }>;
  } | null;
};

export type RemoveComponentFromBillingCodeMutationVariables = Exact<{
  instance: Scalars['BillingCodeNodeField'];
  component: Scalars['ComponentNodeField'];
}>;

export type RemoveComponentFromBillingCodeMutation = {
  removeComponentFromBillingCode?: {
    id: string;
    inactive: boolean;
    realId: number;
    tagId?: string | null;
    tagLabel?: string | null;
    components: Array<{ id: string; name: string }>;
  } | null;
};

export type RemoveComponentFromProductMutationVariables = Exact<{
  data: RemoveComponentFromProductInput;
}>;

export type RemoveComponentFromProductMutation = {
  removeComponentFromProduct?: {
    components: Array<{
      id: string;
      realId: number;
      compactId: string;
      name: string;
      description: string;
      inactive: boolean;
      repository?: { id: string; realId: number; compactId: string; url: string } | null;
    }>;
  } | null;
};

export type RemoveRoleFromRateCardMutationVariables = Exact<{
  data: RemoveRoleFromRateCardInput;
}>;

export type RemoveRoleFromRateCardMutation = {
  removeRoleFromRateCard?: {
    realId: number;
    id: string;
    rateCardRoles: Array<{
      role: { compactId: string; description: string; id: string; name: string; realId: number };
    }>;
  } | null;
};

export type UpdateBillingCodeComponentsMutationVariables = Exact<{
  data: BillingCodeComponentsInput;
}>;

export type UpdateBillingCodeComponentsMutation = {
  billingCodeComponents?: {
    data?: { id: string; internalProject?: { id: string; name: string } | null } | null;
  } | null;
};

export type UpsertBillingCodeMutationVariables = Exact<{
  data: BillingCodeInput;
}>;

export type UpsertBillingCodeMutation = {
  billingCode?: {
    data?: { id: string; internalProject?: { id: string; name: string } | null } | null;
  } | null;
};

export type UpsertClientMutationVariables = Exact<{
  data: ClientInput;
}>;

export type UpsertClientMutation = {
  client?: { data?: { id: string; name: string; inactive: boolean } | null } | null;
};

export type UpsertComponentMutationVariables = Exact<{
  data: ComponentInput;
}>;

export type UpsertComponentMutation = {
  component?: { data?: { id: string; name: string } | null } | null;
};

export type UpsertContractRateCardMutationVariables = Exact<{
  data: ContractRateCardInput;
}>;

export type UpsertContractRateCardMutation = {
  contractRateCard?: { data?: { id: string; dateFrom: any } | null } | null;
};

export type UpsertContractMutationVariables = Exact<{
  data: ContractInput;
}>;

export type UpsertContractMutation = {
  contract?: {
    data?: {
      id: string;
      name: string;
      description: string;
      documentUrl?: string | null;
      created?: any | null;
      submitted?: any | null;
      signed?: any | null;
      validUntil?: any | null;
      completed?: any | null;
      status?: string | null;
      client: { id: string; name: string };
      accountManager: { id: string; name: string };
      budget?: {
        asString?: any | null;
        amount: number;
        amountStr?: string | null;
        currencyDetail?: {
          code: string;
          name: string;
          numeric: string;
          symbol: string;
          prefix: string;
          suffix: string;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UpsertIdentityMutationVariables = Exact<{
  data: IdentityInput;
}>;

export type UpsertIdentityMutation = { identity?: { data?: { id: string } | null } | null };

export type UpsertProductMutationVariables = Exact<{
  data: ProductInput;
}>;

export type UpsertProductMutation = {
  product?: { data?: { id: string; name: string } | null } | null;
};

export type UpsertProfitCentreMutationVariables = Exact<{
  data: ProfitCentreInput;
}>;

export type UpsertProfitCentreMutation = {
  profitCentre?: {
    data?: {
      id: string;
      name: string;
      manager?: { id: string; name: string } | null;
      parent?: { id: string; name: string } | null;
    } | null;
  } | null;
};

export type UpsertProjectRoleAssignmentMutationVariables = Exact<{
  data: ProjectRoleAssignmentInput;
}>;

export type UpsertProjectRoleAssignmentMutation = {
  projectRoleAssignment?: { data?: { id: string } | null } | null;
};

export type UpsertProjectMutationVariables = Exact<{
  data: ProjectInput;
}>;

export type UpsertProjectMutation = {
  project?: { data?: { id: string; name: string } | null } | null;
};

export type UpsertRateCardMutationVariables = Exact<{
  data: RateCardInput;
}>;

export type UpsertRateCardMutation = {
  rateCard?: { data?: { id: string; title: string } | null } | null;
};

export type UpsertRoleMutationVariables = Exact<{
  data: RoleInput;
}>;

export type UpsertRoleMutation = {
  role?: { data?: { id: string; name: string; description: string } | null } | null;
};

export type UpsertUserMutationVariables = Exact<{
  data: UserInput;
}>;

export type UpsertUserMutation = {
  user?: {
    data?: {
      id: string;
      name: string;
      username: string;
      salaryHoursPerFortnight?: any | null;
      email: string;
      hub?: { id: string; name: string; layer: string } | null;
    } | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']>;
}>;

export type RefreshTokenMutation = {
  refreshToken?: {
    payload: any;
    refreshExpiresIn: number;
    token: string;
    refreshToken: string;
  } | null;
};

export type GetMeQueryVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
}>;

export type GetMeQuery = {
  me?: {
    id: string;
    lastLogin?: any | null;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    inactive: boolean;
    name: string;
  } | null;
};

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  tokenAuth?: {
    payload: any;
    refreshExpiresIn: number;
    token: string;
    refreshToken: string;
  } | null;
};

export type RevokeTokenMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']>;
}>;

export type RevokeTokenMutation = { revokeToken?: { revoked: number } | null };

export type GetBillingCodeQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['BillingCodeNodeField']>;
}>;

export type GetBillingCodeQuery = {
  billingCode?: {
    id: string;
    realId: number;
    compactId: string;
    inactive: boolean;
    internalProject?: { id: string; name: string } | null;
    productObjective?: { id: string; name?: string | null; longName?: string | null } | null;
    components: Array<{
      id: string;
      name: string;
      repository?: { id: string; url: string } | null;
    }>;
    totalSpent?: { amount: number; currency: string } | null;
    billingCodes?: Array<{
      internalProject?: { id: string; name: string; contract: { id: string; name: string } } | null;
      productObjective?: { id: string; name?: string | null; longName?: string | null } | null;
    }> | null;
  } | null;
};

export type GetClientQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['ClientNodeField']>;
}>;

export type GetClientQuery = {
  client?: {
    id: string;
    compactId: string;
    name: string;
    inactive: boolean;
    totalBudget?: {
      asString?: any | null;
      amount: number;
      amountStr?: string | null;
      currency: string;
    } | null;
    contracts: Array<{
      id: string;
      name: string;
      signed?: any | null;
      validUntil?: any | null;
      budget?: {
        asString?: any | null;
        amount: number;
        amountStr?: string | null;
        currency: string;
      } | null;
      projects: Array<{ id: string; name: string }>;
    }>;
  } | null;
};

export type GetComponentQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['ComponentNodeField']>;
}>;

export type GetComponentQuery = {
  component?: {
    id: string;
    realId: number;
    compactId: string;
    name: string;
    inactive: boolean;
    repository?: { id: string; url: string } | null;
  } | null;
};

export type GetContractQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['ContractNodeField']>;
}>;

export type GetContractQuery = {
  contract?: {
    id: string;
    realId: number;
    compactId: string;
    name: string;
    description: string;
    documentUrl?: string | null;
    created?: any | null;
    submitted?: any | null;
    signed?: any | null;
    validUntil?: any | null;
    completed?: any | null;
    status?: string | null;
    client: { id: string; name: string };
    entity: { id: string; name: string };
    accountManager: { id: string; name: string };
    projects: Array<{
      id: string;
      name: string;
      status: ProjectStatusEnum;
      product: {
        id: string;
        name: string;
        productObjectives: Array<{ id: string; name?: string | null }>;
        components: Array<{
          id: string;
          name: string;
          repository?: { id: string; url: string } | null;
        }>;
      };
      billingCodes: Array<{
        id: string;
        tagLabel?: string | null;
        productObjective?: { id: string; name?: string | null } | null;
        components: Array<{
          id: string;
          name: string;
          repository?: { id: string; url: string } | null;
        }>;
      }>;
    }>;
    budget?: {
      asString?: any | null;
      amount: number;
      amountStr?: string | null;
      currency: string;
    } | null;
    contractRateCards: Array<{
      id: string;
      dateFrom: any;
      contract?: { id: string; name: string } | null;
      rateCard: {
        id: string;
        title: string;
        rateCardRoles: Array<{
          id: string;
          unit?: RateCardRoleUnit | null;
          role: { id: string; name: string };
          price?: {
            asString?: any | null;
            amount: number;
            amountStr?: string | null;
            currency: string;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type GetProductQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['ProductNodeField']>;
}>;

export type GetProductQuery = {
  product?: {
    id: string;
    realId: number;
    compactId: string;
    name: string;
    description: string;
    inactive: boolean;
    owner: { id: string; name: string };
    components: Array<{
      id: string;
      realId: number;
      compactId: string;
      name: string;
      inactive: boolean;
      repository?: { id: string; url: string } | null;
    }>;
    productObjectives: Array<{
      id: string;
      realId: number;
      compactId: string;
      longName?: string | null;
      shortName: string;
      inactive: boolean;
    }>;
  } | null;
};

export type GetProfitCentreQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['ProfitCentreNodeField']>;
}>;

export type GetProfitCentreQuery = {
  profitCentre?: {
    id: string;
    compactId: string;
    name: string;
    layer: string;
    managerRoleName: string;
    manager?: { id: string; name: string } | null;
    parent?: { id: string; name: string } | null;
  } | null;
};

export type GetProjectQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['InternalProjectNodeField']>;
}>;

export type GetProjectQuery = {
  project?: {
    id: string;
    realId: number;
    compactId: string;
    name: string;
    description: string;
    startDate?: any | null;
    endDate?: any | null;
    status: ProjectStatusEnum;
    fixedPrice: boolean;
    completed: boolean;
    accountManagerApproved: boolean;
    workAtRisk: boolean;
    overburn: boolean;
    contract: { id: string; name: string };
    product: {
      id: string;
      name: string;
      components: Array<{
        id: string;
        name: string;
        repository?: { id: string; url: string } | null;
      }>;
    };
    manager?: { id: string; name: string } | null;
    budget?: { amount: number; asString?: any | null; currency: string } | null;
    billingCodes: Array<{
      id: string;
      tagLabel?: string | null;
      productObjective?: { id: string; name?: string | null; longName?: string | null } | null;
      components: Array<{ id: string; name: string }>;
    }>;
    peopleAssignments: Array<{
      id: string;
      person?: { id: string; name: string } | null;
      role?: { id: string; name: string } | null;
    }>;
  } | null;
};

export type GetRateCardQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['RateCardNodeField']>;
}>;

export type GetRateCardQuery = {
  rateCard?: {
    id: string;
    compactId: string;
    title: string;
    rateCardRoles: Array<{
      id: string;
      unit?: RateCardRoleUnit | null;
      role: { id: string; name: string };
      price?: {
        asString?: any | null;
        amount: number;
        amountStr?: string | null;
        currency: string;
        currencyDetail?: {
          code: string;
          name: string;
          numeric: string;
          symbol: string;
          prefix: string;
          suffix: string;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetRoleQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['RoleNodeField']>;
}>;

export type GetRoleQuery = {
  role?: {
    id: string;
    compactId: string;
    name: string;
    description: string;
    people: Array<{
      id: string;
      name: string;
      hub?: { id: string; name: string; region?: { id: string; name: string } | null } | null;
    }>;
    peopleAssignments: Array<{
      id: string;
      person?: { id: string; name: string; email: string } | null;
      role?: { id: string; name: string } | null;
      project?: { id: string; name: string; contract: { id: string; name: string } } | null;
    }>;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  gId?: InputMaybe<Scalars['UserNodeField']>;
}>;

export type GetUserQuery = {
  user?: {
    id: string;
    realId: number;
    compactId: string;
    name: string;
    username: string;
    email: string;
    salaryHoursPerFortnight?: any | null;
    inactive: boolean;
    hub?: { id: string; name: string } | null;
    roles?: Array<{ id: string; name: string } | null> | null;
    identities: Array<{
      id: string;
      primaryIdentifier?: string | null;
      context?: { id: string; name: string } | null;
    }>;
    projectAssignments: Array<{
      id: string;
      person?: { id: string; name: string; email: string } | null;
      role?: { id: string; name: string } | null;
      project?: { id: string; name: string; contract: { id: string; name: string } } | null;
    }>;
  } | null;
};

export type ListBillingCodesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type ListBillingCodesQuery = {
  billingCodes?: {
    edges: Array<{
      node?: {
        id: string;
        realId: number;
        tagId?: string | null;
        tagLabel?: string | null;
        internalProject?: { id: string; name: string } | null;
        productObjective?: { id: string; name?: string | null } | null;
        totalSpent?: {
          asString?: any | null;
          amount: number;
          amountStr?: string | null;
          currency: string;
        } | null;
      } | null;
    } | null>;
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
  } | null;
};

export type ListClientsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  contractEntityId?: InputMaybe<Scalars['String']>;
  contractIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ListClientsQuery = {
  clients?: {
    edges: Array<{ node?: { id: string; name: string } | null } | null>;
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
  } | null;
};

export type ListComponentsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type ListComponentsQuery = {
  components?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      node?: {
        id: string;
        name: string;
        repository?: { id: string; url: string } | null;
        products?: Array<{
          id: string;
          compactId: string;
          name: string;
          description: string;
        } | null> | null;
      } | null;
    } | null>;
  } | null;
};

export type ListContextsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type ListContextsQuery = {
  contexts?: {
    edges: Array<{
      cursor: string;
      node?: { id: string; name: string; description?: string | null } | null;
    } | null>;
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
  } | null;
};

export type ListContractsWithProjectsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  active?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ListContractsWithProjectsQuery = {
  contracts?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        name: string;
        description: string;
        signed?: any | null;
        submitted?: any | null;
        validUntil?: any | null;
        status?: string | null;
        client: { id: string; name: string };
        entity: { id: string; name: string };
        accountManager: { id: string; name: string };
        projects: Array<{
          id: string;
          compactId: string;
          name: string;
          status: ProjectStatusEnum;
          product: { id: string; name: string };
        }>;
      } | null;
    } | null>;
  } | null;
};

export type ListContractsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  active?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ListContractsQuery = {
  contracts?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        name: string;
        description: string;
        signed?: any | null;
        submitted?: any | null;
        validUntil?: any | null;
        status?: string | null;
        client: { id: string; name: string };
        entity: { id: string; name: string };
        accountManager: { id: string; name: string };
      } | null;
    } | null>;
  } | null;
};

export type ListEntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type ListEntitiesQuery = {
  entities: Array<{
    id: string;
    compactId: string;
    name: string;
    managerRoleName: string;
    layer: string;
    manager?: { id: string; name: string } | null;
    parent?: { id: string; name: string; layer: string } | null;
    regions: Array<{
      id: string;
      compactId: string;
      name: string;
      managerRoleName: string;
      layer: string;
      manager?: { id: string; name: string } | null;
      parent?: { id: string; name: string; layer: string } | null;
      hubs: Array<{
        id: string;
        compactId: string;
        name: string;
        managerRoleName: string;
        layer: string;
        manager?: { id: string; name: string } | null;
        parent?: { id: string; name: string; layer: string } | null;
      }>;
    }>;
    contractSet: {
      edges: Array<{
        node?: {
          id: string;
          compactId: string;
          name: string;
          status?: string | null;
          accountManager: { id: string; name: string };
        } | null;
      } | null>;
    };
  }>;
};

export type ListProductObjectivesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
}>;

export type ListProductObjectivesQuery = {
  productObjectives?: {
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      cursor: string;
      node?: { id: string; shortName: string; longName?: string | null } | null;
    } | null>;
    pageCursors?: {
      totalRecords?: number | null;
      totalPages?: number | null;
      around?: Array<{
        cursor?: string | null;
        isCurrent?: boolean | null;
        page?: number | null;
      } | null> | null;
      first?: { cursor?: string | null; isCurrent?: boolean | null; page?: number | null } | null;
      last?: { cursor?: string | null; isCurrent?: boolean | null; page?: number | null } | null;
      next?: { cursor?: string | null; isCurrent?: boolean | null; page?: number | null } | null;
      previous?: {
        cursor?: string | null;
        isCurrent?: boolean | null;
        page?: number | null;
      } | null;
    } | null;
  } | null;
};

export type ListProductsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ListProductsQuery = {
  products?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        realId: number;
        name: string;
        owner: { id: string; name: string };
      } | null;
    } | null>;
  } | null;
};

export type ListProfitCentresQueryVariables = Exact<{
  types?: InputMaybe<Array<InputMaybe<ProfitCentreLayer>> | InputMaybe<ProfitCentreLayer>>;
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
}>;

export type ListProfitCentresQuery = {
  profitCentres?: {
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        name: string;
        layer: string;
        managerRoleName: string;
        manager?: { id: string; name: string } | null;
        region?: { id: string; name: string } | null;
        entity?: { id: string; name: string } | null;
      } | null;
    } | null>;
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
  } | null;
};

export type ListProjectsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  contractId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  clientIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ListProjectsQuery = {
  projects?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        compactId: string;
        status: ProjectStatusEnum;
        name: string;
        contract: { id: string; name: string; accountManager: { id: string; name: string } };
        product: { id: string; name: string };
        manager?: { id: string; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type ListRateCardsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type ListRateCardsQuery = {
  rateCards?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{ node?: { id: string; title: string } | null } | null>;
  } | null;
};

export type ListRolesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type ListRolesQuery = {
  roles?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{ node?: { id: string; name: string } | null } | null>;
  } | null;
};

export type ListUsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  hubId?: InputMaybe<Scalars['String']>;
  hubIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  includeInactive?: InputMaybe<Scalars['Boolean']>;
}>;

export type ListUsersQuery = {
  users?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        name: string;
        inactive: boolean;
        username: string;
        projectCount?: number | null;
        roles?: Array<{ name: string } | null> | null;
        hub?: { id: string; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type SearchProjectsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  orderBy?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  needDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type SearchProjectsQuery = {
  projects?: {
    pageCursors?: { totalRecords?: number | null; totalPages?: number | null } | null;
    edges: Array<{
      cursor: string;
      node?: {
        id: string;
        name: string;
        status: ProjectStatusEnum;
        startDate?: any | null;
        endDate?: any | null;
        budget?: {
          asString?: any | null;
          amount: number;
          amountStr?: string | null;
          currency: string;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export const DeleteProjectRoleAssignmentDocument = gql`
  mutation deleteProjectRoleAssignment($instance: ProjectRoleAssignmentNodeField!) {
    deleteProjectRoleAssignment(data: { instance: $instance }) {
      data {
        id
      }
    }
  }
`;

export const DeleteProjectRoleAssignmentComponent = (
  props: Omit<
    Urql.MutationProps<
      DeleteProjectRoleAssignmentMutation,
      DeleteProjectRoleAssignmentMutationVariables
    >,
    'query'
  > & { variables?: DeleteProjectRoleAssignmentMutationVariables },
) => <Urql.Mutation {...props} query={DeleteProjectRoleAssignmentDocument} />;

export function useDeleteProjectRoleAssignmentMutation() {
  return Urql.useMutation<
    DeleteProjectRoleAssignmentMutation,
    DeleteProjectRoleAssignmentMutationVariables
  >(DeleteProjectRoleAssignmentDocument);
}
export const AddComponentToBillingCodeDocument = gql`
  mutation addComponentToBillingCode(
    $instance: BillingCodeNodeField!
    $component: ComponentNodeField!
  ) {
    addComponentToBillingCode(data: { instance: $instance, component: $component }) {
      components {
        id
        name
      }
      id
      inactive
      realId
      tagId
      tagLabel
    }
  }
`;

export const AddComponentToBillingCodeComponent = (
  props: Omit<
    Urql.MutationProps<
      AddComponentToBillingCodeMutation,
      AddComponentToBillingCodeMutationVariables
    >,
    'query'
  > & { variables?: AddComponentToBillingCodeMutationVariables },
) => <Urql.Mutation {...props} query={AddComponentToBillingCodeDocument} />;

export function useAddComponentToBillingCodeMutation() {
  return Urql.useMutation<
    AddComponentToBillingCodeMutation,
    AddComponentToBillingCodeMutationVariables
  >(AddComponentToBillingCodeDocument);
}
export const AddComponentToProductDocument = gql`
  mutation AddComponentToProduct($data: AddComponentToProductInput!) {
    addComponentToProduct(data: $data) {
      components {
        id
        realId
        compactId
        name
        description
        inactive
        repository {
          id
          realId
          compactId
          url
        }
      }
    }
  }
`;

export const AddComponentToProductComponent = (
  props: Omit<
    Urql.MutationProps<AddComponentToProductMutation, AddComponentToProductMutationVariables>,
    'query'
  > & { variables?: AddComponentToProductMutationVariables },
) => <Urql.Mutation {...props} query={AddComponentToProductDocument} />;

export function useAddComponentToProductMutation() {
  return Urql.useMutation<AddComponentToProductMutation, AddComponentToProductMutationVariables>(
    AddComponentToProductDocument,
  );
}
export const AddRoleToRateCardDocument = gql`
  mutation addRoleToRateCard($data: AddRoleToRateCardInput!) {
    addRoleToRateCard(data: $data) {
      realId
      id
      rateCardRoles {
        role {
          compactId
          description
          id
          name
          realId
        }
      }
    }
  }
`;

export const AddRoleToRateCardComponent = (
  props: Omit<
    Urql.MutationProps<AddRoleToRateCardMutation, AddRoleToRateCardMutationVariables>,
    'query'
  > & { variables?: AddRoleToRateCardMutationVariables },
) => <Urql.Mutation {...props} query={AddRoleToRateCardDocument} />;

export function useAddRoleToRateCardMutation() {
  return Urql.useMutation<AddRoleToRateCardMutation, AddRoleToRateCardMutationVariables>(
    AddRoleToRateCardDocument,
  );
}
export const RemoveComponentFromBillingCodeDocument = gql`
  mutation removeComponentFromBillingCode(
    $instance: BillingCodeNodeField!
    $component: ComponentNodeField!
  ) {
    removeComponentFromBillingCode(data: { instance: $instance, component: $component }) {
      components {
        id
        name
      }
      id
      inactive
      realId
      tagId
      tagLabel
    }
  }
`;

export const RemoveComponentFromBillingCodeComponent = (
  props: Omit<
    Urql.MutationProps<
      RemoveComponentFromBillingCodeMutation,
      RemoveComponentFromBillingCodeMutationVariables
    >,
    'query'
  > & { variables?: RemoveComponentFromBillingCodeMutationVariables },
) => <Urql.Mutation {...props} query={RemoveComponentFromBillingCodeDocument} />;

export function useRemoveComponentFromBillingCodeMutation() {
  return Urql.useMutation<
    RemoveComponentFromBillingCodeMutation,
    RemoveComponentFromBillingCodeMutationVariables
  >(RemoveComponentFromBillingCodeDocument);
}
export const RemoveComponentFromProductDocument = gql`
  mutation RemoveComponentFromProduct($data: RemoveComponentFromProductInput!) {
    removeComponentFromProduct(data: $data) {
      components {
        id
        realId
        compactId
        name
        description
        inactive
        repository {
          id
          realId
          compactId
          url
        }
      }
    }
  }
`;

export const RemoveComponentFromProductComponent = (
  props: Omit<
    Urql.MutationProps<
      RemoveComponentFromProductMutation,
      RemoveComponentFromProductMutationVariables
    >,
    'query'
  > & { variables?: RemoveComponentFromProductMutationVariables },
) => <Urql.Mutation {...props} query={RemoveComponentFromProductDocument} />;

export function useRemoveComponentFromProductMutation() {
  return Urql.useMutation<
    RemoveComponentFromProductMutation,
    RemoveComponentFromProductMutationVariables
  >(RemoveComponentFromProductDocument);
}
export const RemoveRoleFromRateCardDocument = gql`
  mutation removeRoleFromRateCard($data: RemoveRoleFromRateCardInput!) {
    removeRoleFromRateCard(data: $data) {
      realId
      id
      rateCardRoles {
        role {
          compactId
          description
          id
          name
          realId
        }
      }
    }
  }
`;

export const RemoveRoleFromRateCardComponent = (
  props: Omit<
    Urql.MutationProps<RemoveRoleFromRateCardMutation, RemoveRoleFromRateCardMutationVariables>,
    'query'
  > & { variables?: RemoveRoleFromRateCardMutationVariables },
) => <Urql.Mutation {...props} query={RemoveRoleFromRateCardDocument} />;

export function useRemoveRoleFromRateCardMutation() {
  return Urql.useMutation<RemoveRoleFromRateCardMutation, RemoveRoleFromRateCardMutationVariables>(
    RemoveRoleFromRateCardDocument,
  );
}
export const UpdateBillingCodeComponentsDocument = gql`
  mutation UpdateBillingCodeComponents($data: BillingCodeComponentsInput!) {
    billingCodeComponents(data: $data) {
      data {
        id
        internalProject {
          id
          name
        }
      }
    }
  }
`;

export const UpdateBillingCodeComponentsComponent = (
  props: Omit<
    Urql.MutationProps<
      UpdateBillingCodeComponentsMutation,
      UpdateBillingCodeComponentsMutationVariables
    >,
    'query'
  > & { variables?: UpdateBillingCodeComponentsMutationVariables },
) => <Urql.Mutation {...props} query={UpdateBillingCodeComponentsDocument} />;

export function useUpdateBillingCodeComponentsMutation() {
  return Urql.useMutation<
    UpdateBillingCodeComponentsMutation,
    UpdateBillingCodeComponentsMutationVariables
  >(UpdateBillingCodeComponentsDocument);
}
export const UpsertBillingCodeDocument = gql`
  mutation UpsertBillingCode($data: BillingCodeInput!) {
    billingCode(data: $data) {
      data {
        id
        internalProject {
          id
          name
        }
      }
    }
  }
`;

export const UpsertBillingCodeComponent = (
  props: Omit<
    Urql.MutationProps<UpsertBillingCodeMutation, UpsertBillingCodeMutationVariables>,
    'query'
  > & { variables?: UpsertBillingCodeMutationVariables },
) => <Urql.Mutation {...props} query={UpsertBillingCodeDocument} />;

export function useUpsertBillingCodeMutation() {
  return Urql.useMutation<UpsertBillingCodeMutation, UpsertBillingCodeMutationVariables>(
    UpsertBillingCodeDocument,
  );
}
export const UpsertClientDocument = gql`
  mutation UpsertClient($data: ClientInput!) {
    client(data: $data) {
      data {
        id
        name
        inactive
      }
    }
  }
`;

export const UpsertClientComponent = (
  props: Omit<Urql.MutationProps<UpsertClientMutation, UpsertClientMutationVariables>, 'query'> & {
    variables?: UpsertClientMutationVariables;
  },
) => <Urql.Mutation {...props} query={UpsertClientDocument} />;

export function useUpsertClientMutation() {
  return Urql.useMutation<UpsertClientMutation, UpsertClientMutationVariables>(
    UpsertClientDocument,
  );
}
export const UpsertComponentDocument = gql`
  mutation UpsertComponent($data: ComponentInput!) {
    component(data: $data) {
      data {
        id
        name
      }
    }
  }
`;

export const UpsertComponentComponent = (
  props: Omit<
    Urql.MutationProps<UpsertComponentMutation, UpsertComponentMutationVariables>,
    'query'
  > & { variables?: UpsertComponentMutationVariables },
) => <Urql.Mutation {...props} query={UpsertComponentDocument} />;

export function useUpsertComponentMutation() {
  return Urql.useMutation<UpsertComponentMutation, UpsertComponentMutationVariables>(
    UpsertComponentDocument,
  );
}
export const UpsertContractRateCardDocument = gql`
  mutation UpsertContractRateCard($data: ContractRateCardInput!) {
    contractRateCard(data: $data) {
      data {
        id
        dateFrom
      }
    }
  }
`;

export const UpsertContractRateCardComponent = (
  props: Omit<
    Urql.MutationProps<UpsertContractRateCardMutation, UpsertContractRateCardMutationVariables>,
    'query'
  > & { variables?: UpsertContractRateCardMutationVariables },
) => <Urql.Mutation {...props} query={UpsertContractRateCardDocument} />;

export function useUpsertContractRateCardMutation() {
  return Urql.useMutation<UpsertContractRateCardMutation, UpsertContractRateCardMutationVariables>(
    UpsertContractRateCardDocument,
  );
}
export const UpsertContractDocument = gql`
  mutation UpsertContract($data: ContractInput!) {
    contract(data: $data) {
      data {
        id
        client {
          id
          name
        }
        name
        description
        accountManager {
          id
          name
        }
        documentUrl
        created
        submitted
        signed
        validUntil
        completed
        status
        budget {
          asString
          amount
          amountStr
          currencyDetail {
            code
            name
            numeric
            symbol
            prefix
            suffix
          }
        }
      }
    }
  }
`;

export const UpsertContractComponent = (
  props: Omit<
    Urql.MutationProps<UpsertContractMutation, UpsertContractMutationVariables>,
    'query'
  > & { variables?: UpsertContractMutationVariables },
) => <Urql.Mutation {...props} query={UpsertContractDocument} />;

export function useUpsertContractMutation() {
  return Urql.useMutation<UpsertContractMutation, UpsertContractMutationVariables>(
    UpsertContractDocument,
  );
}
export const UpsertIdentityDocument = gql`
  mutation UpsertIdentity($data: IdentityInput!) {
    identity(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UpsertIdentityComponent = (
  props: Omit<
    Urql.MutationProps<UpsertIdentityMutation, UpsertIdentityMutationVariables>,
    'query'
  > & { variables?: UpsertIdentityMutationVariables },
) => <Urql.Mutation {...props} query={UpsertIdentityDocument} />;

export function useUpsertIdentityMutation() {
  return Urql.useMutation<UpsertIdentityMutation, UpsertIdentityMutationVariables>(
    UpsertIdentityDocument,
  );
}
export const UpsertProductDocument = gql`
  mutation UpsertProduct($data: ProductInput!) {
    product(data: $data) {
      data {
        id
        name
      }
    }
  }
`;

export const UpsertProductComponent = (
  props: Omit<
    Urql.MutationProps<UpsertProductMutation, UpsertProductMutationVariables>,
    'query'
  > & { variables?: UpsertProductMutationVariables },
) => <Urql.Mutation {...props} query={UpsertProductDocument} />;

export function useUpsertProductMutation() {
  return Urql.useMutation<UpsertProductMutation, UpsertProductMutationVariables>(
    UpsertProductDocument,
  );
}
export const UpsertProfitCentreDocument = gql`
  mutation UpsertProfitCentre($data: ProfitCentreInput!) {
    profitCentre(data: $data) {
      data {
        id
        name
        manager {
          id
          name
        }
        parent {
          id
          name
        }
      }
    }
  }
`;

export const UpsertProfitCentreComponent = (
  props: Omit<
    Urql.MutationProps<UpsertProfitCentreMutation, UpsertProfitCentreMutationVariables>,
    'query'
  > & { variables?: UpsertProfitCentreMutationVariables },
) => <Urql.Mutation {...props} query={UpsertProfitCentreDocument} />;

export function useUpsertProfitCentreMutation() {
  return Urql.useMutation<UpsertProfitCentreMutation, UpsertProfitCentreMutationVariables>(
    UpsertProfitCentreDocument,
  );
}
export const UpsertProjectRoleAssignmentDocument = gql`
  mutation UpsertProjectRoleAssignment($data: ProjectRoleAssignmentInput!) {
    projectRoleAssignment(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UpsertProjectRoleAssignmentComponent = (
  props: Omit<
    Urql.MutationProps<
      UpsertProjectRoleAssignmentMutation,
      UpsertProjectRoleAssignmentMutationVariables
    >,
    'query'
  > & { variables?: UpsertProjectRoleAssignmentMutationVariables },
) => <Urql.Mutation {...props} query={UpsertProjectRoleAssignmentDocument} />;

export function useUpsertProjectRoleAssignmentMutation() {
  return Urql.useMutation<
    UpsertProjectRoleAssignmentMutation,
    UpsertProjectRoleAssignmentMutationVariables
  >(UpsertProjectRoleAssignmentDocument);
}
export const UpsertProjectDocument = gql`
  mutation UpsertProject($data: ProjectInput!) {
    project(data: $data) {
      data {
        id
        name
      }
    }
  }
`;

export const UpsertProjectComponent = (
  props: Omit<
    Urql.MutationProps<UpsertProjectMutation, UpsertProjectMutationVariables>,
    'query'
  > & { variables?: UpsertProjectMutationVariables },
) => <Urql.Mutation {...props} query={UpsertProjectDocument} />;

export function useUpsertProjectMutation() {
  return Urql.useMutation<UpsertProjectMutation, UpsertProjectMutationVariables>(
    UpsertProjectDocument,
  );
}
export const UpsertRateCardDocument = gql`
  mutation UpsertRateCard($data: RateCardInput!) {
    rateCard(data: $data) {
      data {
        id
        title
      }
    }
  }
`;

export const UpsertRateCardComponent = (
  props: Omit<
    Urql.MutationProps<UpsertRateCardMutation, UpsertRateCardMutationVariables>,
    'query'
  > & { variables?: UpsertRateCardMutationVariables },
) => <Urql.Mutation {...props} query={UpsertRateCardDocument} />;

export function useUpsertRateCardMutation() {
  return Urql.useMutation<UpsertRateCardMutation, UpsertRateCardMutationVariables>(
    UpsertRateCardDocument,
  );
}
export const UpsertRoleDocument = gql`
  mutation UpsertRole($data: RoleInput!) {
    role(data: $data) {
      data {
        id
        name
        description
      }
    }
  }
`;

export const UpsertRoleComponent = (
  props: Omit<Urql.MutationProps<UpsertRoleMutation, UpsertRoleMutationVariables>, 'query'> & {
    variables?: UpsertRoleMutationVariables;
  },
) => <Urql.Mutation {...props} query={UpsertRoleDocument} />;

export function useUpsertRoleMutation() {
  return Urql.useMutation<UpsertRoleMutation, UpsertRoleMutationVariables>(UpsertRoleDocument);
}
export const UpsertUserDocument = gql`
  mutation UpsertUser($data: UserInput!) {
    user(data: $data) {
      data {
        id
        name
        username
        salaryHoursPerFortnight
        email
        hub {
          id
          name
          layer
        }
      }
    }
  }
`;

export const UpsertUserComponent = (
  props: Omit<Urql.MutationProps<UpsertUserMutation, UpsertUserMutationVariables>, 'query'> & {
    variables?: UpsertUserMutationVariables;
  },
) => <Urql.Mutation {...props} query={UpsertUserDocument} />;

export function useUpsertUserMutation() {
  return Urql.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(UpsertUserDocument);
}
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String) {
    refreshToken(refreshToken: $refreshToken) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`;

export const RefreshTokenComponent = (
  props: Omit<Urql.MutationProps<RefreshTokenMutation, RefreshTokenMutationVariables>, 'query'> & {
    variables?: RefreshTokenMutationVariables;
  },
) => <Urql.Mutation {...props} query={RefreshTokenDocument} />;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
  );
}
export const GetMeDocument = gql`
  query GetMe($token: String) {
    me(token: $token) {
      id
      lastLogin
      username
      firstName
      lastName
      email
      inactive
      name
    }
  }
`;

export const GetMeComponent = (
  props: Omit<Urql.QueryProps<GetMeQuery, GetMeQueryVariables>, 'query'> & {
    variables?: GetMeQueryVariables;
  },
) => <Urql.Query {...props} query={GetMeDocument} />;

export function useGetMeQuery(options?: Omit<Urql.UseQueryArgs<GetMeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeQuery, GetMeQueryVariables>({ query: GetMeDocument, ...options });
}
export const LoginDocument = gql`
  mutation Login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`;

export const LoginComponent = (
  props: Omit<Urql.MutationProps<LoginMutation, LoginMutationVariables>, 'query'> & {
    variables?: LoginMutationVariables;
  },
) => <Urql.Mutation {...props} query={LoginDocument} />;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
export const RevokeTokenDocument = gql`
  mutation RevokeToken($refreshToken: String) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

export const RevokeTokenComponent = (
  props: Omit<Urql.MutationProps<RevokeTokenMutation, RevokeTokenMutationVariables>, 'query'> & {
    variables?: RevokeTokenMutationVariables;
  },
) => <Urql.Mutation {...props} query={RevokeTokenDocument} />;

export function useRevokeTokenMutation() {
  return Urql.useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(RevokeTokenDocument);
}
export const GetBillingCodeDocument = gql`
  query GetBillingCode($gId: BillingCodeNodeField) {
    billingCode(gId: $gId) {
      id
      realId
      compactId
      inactive
      internalProject {
        id
        name
      }
      productObjective {
        id
        name
        longName
      }
      components {
        id
        name
        repository {
          id
          url
        }
      }
      totalSpent {
        amount
        currency
      }
      billingCodes {
        internalProject {
          id
          name
          contract {
            id
            name
          }
        }
        productObjective {
          id
          name
          longName
        }
      }
    }
  }
`;

export const GetBillingCodeComponent = (
  props: Omit<Urql.QueryProps<GetBillingCodeQuery, GetBillingCodeQueryVariables>, 'query'> & {
    variables?: GetBillingCodeQueryVariables;
  },
) => <Urql.Query {...props} query={GetBillingCodeDocument} />;

export function useGetBillingCodeQuery(
  options?: Omit<Urql.UseQueryArgs<GetBillingCodeQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetBillingCodeQuery, GetBillingCodeQueryVariables>({
    query: GetBillingCodeDocument,
    ...options,
  });
}
export const GetClientDocument = gql`
  query GetClient($gId: ClientNodeField) {
    client(gId: $gId) {
      id
      compactId
      name
      inactive
      totalBudget {
        asString
        amount
        amountStr
        currency
      }
      contracts {
        id
        name
        budget {
          asString
          amount
          amountStr
          currency
        }
        signed
        validUntil
        projects {
          id
          name
        }
      }
    }
  }
`;

export const GetClientComponent = (
  props: Omit<Urql.QueryProps<GetClientQuery, GetClientQueryVariables>, 'query'> & {
    variables?: GetClientQueryVariables;
  },
) => <Urql.Query {...props} query={GetClientDocument} />;

export function useGetClientQuery(
  options?: Omit<Urql.UseQueryArgs<GetClientQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetClientQuery, GetClientQueryVariables>({
    query: GetClientDocument,
    ...options,
  });
}
export const GetComponentDocument = gql`
  query GetComponent($gId: ComponentNodeField) {
    component(gId: $gId) {
      id
      realId
      compactId
      name
      inactive
      repository {
        id
        url
      }
    }
  }
`;

export const GetComponentComponent = (
  props: Omit<Urql.QueryProps<GetComponentQuery, GetComponentQueryVariables>, 'query'> & {
    variables?: GetComponentQueryVariables;
  },
) => <Urql.Query {...props} query={GetComponentDocument} />;

export function useGetComponentQuery(
  options?: Omit<Urql.UseQueryArgs<GetComponentQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetComponentQuery, GetComponentQueryVariables>({
    query: GetComponentDocument,
    ...options,
  });
}
export const GetContractDocument = gql`
  query GetContract($gId: ContractNodeField) {
    contract(gId: $gId) {
      id
      realId
      compactId
      client {
        id
        name
      }
      entity {
        id
        name
      }
      name
      description
      accountManager {
        id
        name
      }
      documentUrl
      created
      submitted
      signed
      validUntil
      completed
      status
      projects {
        id
        name
        status
        product {
          id
          name
          productObjectives {
            id
            name
          }
          components {
            id
            name
            repository {
              id
              url
            }
          }
        }
        billingCodes {
          id
          tagLabel
          productObjective {
            id
            name
          }
          components {
            id
            name
            repository {
              id
              url
            }
          }
        }
      }
      budget {
        asString
        amount
        amountStr
        currency
      }
      contractRateCards {
        id
        contract {
          id
          name
        }
        rateCard {
          id
          title
          rateCardRoles {
            id
            role {
              id
              name
            }
            price {
              asString
              amount
              amountStr
              currency
            }
            unit
          }
        }
        dateFrom
      }
    }
  }
`;

export const GetContractComponent = (
  props: Omit<Urql.QueryProps<GetContractQuery, GetContractQueryVariables>, 'query'> & {
    variables?: GetContractQueryVariables;
  },
) => <Urql.Query {...props} query={GetContractDocument} />;

export function useGetContractQuery(
  options?: Omit<Urql.UseQueryArgs<GetContractQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetContractQuery, GetContractQueryVariables>({
    query: GetContractDocument,
    ...options,
  });
}
export const GetProductDocument = gql`
  query GetProduct($gId: ProductNodeField) {
    product(gId: $gId) {
      id
      realId
      compactId
      name
      description
      inactive
      owner {
        id
        name
      }
      components {
        id
        realId
        compactId
        name
        inactive
        repository {
          id
          url
        }
      }
      productObjectives {
        id
        realId
        compactId
        longName
        shortName
        inactive
      }
    }
  }
`;

export const GetProductComponent = (
  props: Omit<Urql.QueryProps<GetProductQuery, GetProductQueryVariables>, 'query'> & {
    variables?: GetProductQueryVariables;
  },
) => <Urql.Query {...props} query={GetProductDocument} />;

export function useGetProductQuery(
  options?: Omit<Urql.UseQueryArgs<GetProductQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetProductQuery, GetProductQueryVariables>({
    query: GetProductDocument,
    ...options,
  });
}
export const GetProfitCentreDocument = gql`
  query GetProfitCentre($gId: ProfitCentreNodeField) {
    profitCentre(gId: $gId) {
      id
      compactId
      name
      layer
      manager {
        id
        name
      }
      managerRoleName
      parent {
        id
        name
      }
    }
  }
`;

export const GetProfitCentreComponent = (
  props: Omit<Urql.QueryProps<GetProfitCentreQuery, GetProfitCentreQueryVariables>, 'query'> & {
    variables?: GetProfitCentreQueryVariables;
  },
) => <Urql.Query {...props} query={GetProfitCentreDocument} />;

export function useGetProfitCentreQuery(
  options?: Omit<Urql.UseQueryArgs<GetProfitCentreQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetProfitCentreQuery, GetProfitCentreQueryVariables>({
    query: GetProfitCentreDocument,
    ...options,
  });
}
export const GetProjectDocument = gql`
  query GetProject($gId: InternalProjectNodeField) {
    project(gId: $gId) {
      id
      realId
      compactId
      name
      description
      contract {
        id
        name
      }
      product {
        id
        name
        components {
          id
          name
          repository {
            id
            url
          }
        }
      }
      manager {
        id
        name
      }
      budget {
        amount
        asString
        currency
      }
      startDate
      endDate
      status
      fixedPrice
      billingCodes {
        id
        tagLabel
        productObjective {
          id
          name
          longName
        }
        components {
          id
          name
        }
      }
      peopleAssignments {
        id
        person {
          id
          name
        }
        role {
          id
          name
        }
      }
      completed
      accountManagerApproved
      workAtRisk
      overburn
    }
  }
`;

export const GetProjectComponent = (
  props: Omit<Urql.QueryProps<GetProjectQuery, GetProjectQueryVariables>, 'query'> & {
    variables?: GetProjectQueryVariables;
  },
) => <Urql.Query {...props} query={GetProjectDocument} />;

export function useGetProjectQuery(
  options?: Omit<Urql.UseQueryArgs<GetProjectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetProjectQuery, GetProjectQueryVariables>({
    query: GetProjectDocument,
    ...options,
  });
}
export const GetRateCardDocument = gql`
  query GetRateCard($gId: RateCardNodeField) {
    rateCard(gId: $gId) {
      id
      compactId
      title
      rateCardRoles {
        id
        role {
          id
          name
        }
        price {
          asString
          amount
          amountStr
          currency
          currencyDetail {
            code
            name
            numeric
            symbol
            prefix
            suffix
          }
        }
        unit
      }
    }
  }
`;

export const GetRateCardComponent = (
  props: Omit<Urql.QueryProps<GetRateCardQuery, GetRateCardQueryVariables>, 'query'> & {
    variables?: GetRateCardQueryVariables;
  },
) => <Urql.Query {...props} query={GetRateCardDocument} />;

export function useGetRateCardQuery(
  options?: Omit<Urql.UseQueryArgs<GetRateCardQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetRateCardQuery, GetRateCardQueryVariables>({
    query: GetRateCardDocument,
    ...options,
  });
}
export const GetRoleDocument = gql`
  query GetRole($gId: RoleNodeField) {
    role(gId: $gId) {
      id
      compactId
      name
      description
      people {
        id
        name
        hub {
          id
          name
          region {
            id
            name
          }
        }
      }
      peopleAssignments {
        id
        person {
          id
          name
          email
        }
        role {
          id
          name
        }
        project {
          id
          name
          contract {
            id
            name
          }
        }
      }
    }
  }
`;

export const GetRoleComponent = (
  props: Omit<Urql.QueryProps<GetRoleQuery, GetRoleQueryVariables>, 'query'> & {
    variables?: GetRoleQueryVariables;
  },
) => <Urql.Query {...props} query={GetRoleDocument} />;

export function useGetRoleQuery(options?: Omit<Urql.UseQueryArgs<GetRoleQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRoleQuery, GetRoleQueryVariables>({ query: GetRoleDocument, ...options });
}
export const GetUserDocument = gql`
  query GetUser($gId: UserNodeField) {
    user(gId: $gId) {
      id
      realId
      compactId
      name
      username
      email
      salaryHoursPerFortnight
      inactive
      hub {
        id
        name
      }
      roles {
        id
        name
      }
      identities {
        id
        context {
          id
          name
        }
        primaryIdentifier
      }
      projectAssignments {
        id
        person {
          id
          name
          email
        }
        role {
          id
          name
        }
        project {
          id
          name
          contract {
            id
            name
          }
        }
      }
    }
  }
`;

export const GetUserComponent = (
  props: Omit<Urql.QueryProps<GetUserQuery, GetUserQueryVariables>, 'query'> & {
    variables?: GetUserQueryVariables;
  },
) => <Urql.Query {...props} query={GetUserDocument} />;

export function useGetUserQuery(options?: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options });
}
export const ListBillingCodesDocument = gql`
  query ListBillingCodes($offset: Int, $first: Int, $search: [String], $orderBy: String) {
    billingCodes(offset: $offset, first: $first, search: $search, orderBy: $orderBy) {
      edges {
        node {
          id
          realId
          tagId
          tagLabel
          internalProject {
            id
            name
          }
          productObjective {
            id
            name
          }
          totalSpent {
            asString
            amount
            amountStr
            currency
          }
        }
      }
      pageCursors {
        totalRecords
        totalPages
      }
    }
  }
`;

export const ListBillingCodesComponent = (
  props: Omit<Urql.QueryProps<ListBillingCodesQuery, ListBillingCodesQueryVariables>, 'query'> & {
    variables?: ListBillingCodesQueryVariables;
  },
) => <Urql.Query {...props} query={ListBillingCodesDocument} />;

export function useListBillingCodesQuery(
  options?: Omit<Urql.UseQueryArgs<ListBillingCodesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListBillingCodesQuery, ListBillingCodesQueryVariables>({
    query: ListBillingCodesDocument,
    ...options,
  });
}
export const ListClientsDocument = gql`
  query ListClients(
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $active: Boolean
    $contractEntityId: String
    $contractIds: [String]
    $productIds: [String]
    $projectIds: [String]
  ) {
    clients(
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      active: $active
      contractEntityId: $contractEntityId
      contractIds: $contractIds
      productIds: $productIds
      projectIds: $projectIds
    ) {
      edges {
        node {
          id
          name
        }
      }
      pageCursors {
        totalRecords
        totalPages
      }
    }
  }
`;

export const ListClientsComponent = (
  props: Omit<Urql.QueryProps<ListClientsQuery, ListClientsQueryVariables>, 'query'> & {
    variables?: ListClientsQueryVariables;
  },
) => <Urql.Query {...props} query={ListClientsDocument} />;

export function useListClientsQuery(
  options?: Omit<Urql.UseQueryArgs<ListClientsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListClientsQuery, ListClientsQueryVariables>({
    query: ListClientsDocument,
    ...options,
  });
}
export const ListComponentsDocument = gql`
  query ListComponents($offset: Int, $first: Int, $search: [String], $orderBy: String) {
    components(offset: $offset, first: $first, search: $search, orderBy: $orderBy) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          name
          repository {
            id
            url
          }
          products {
            id
            compactId
            name
            description
          }
        }
      }
    }
  }
`;

export const ListComponentsComponent = (
  props: Omit<Urql.QueryProps<ListComponentsQuery, ListComponentsQueryVariables>, 'query'> & {
    variables?: ListComponentsQueryVariables;
  },
) => <Urql.Query {...props} query={ListComponentsDocument} />;

export function useListComponentsQuery(
  options?: Omit<Urql.UseQueryArgs<ListComponentsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListComponentsQuery, ListComponentsQueryVariables>({
    query: ListComponentsDocument,
    ...options,
  });
}
export const ListContextsDocument = gql`
  query ListContexts($offset: Int, $first: Int, $search: [String], $orderBy: String) {
    contexts(offset: $offset, first: $first, search: $search, orderBy: $orderBy) {
      edges {
        node {
          id
          name
          description
        }
        cursor
      }
      pageCursors {
        totalRecords
        totalPages
      }
    }
  }
`;

export const ListContextsComponent = (
  props: Omit<Urql.QueryProps<ListContextsQuery, ListContextsQueryVariables>, 'query'> & {
    variables?: ListContextsQueryVariables;
  },
) => <Urql.Query {...props} query={ListContextsDocument} />;

export function useListContextsQuery(
  options?: Omit<Urql.UseQueryArgs<ListContextsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListContextsQuery, ListContextsQueryVariables>({
    query: ListContextsDocument,
    ...options,
  });
}
export const ListContractsWithProjectsDocument = gql`
  query ListContractsWithProjects(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: [String]
    $orderBy: String
    $entityId: String
    $clientId: String
    $clientIds: [String]
    $active: Boolean
    $statuses: [String]
  ) {
    contracts(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      search: $search
      orderBy: $orderBy
      entityId: $entityId
      clientId: $clientId
      clientIds: $clientIds
      active: $active
      statuses: $statuses
    ) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          client {
            id
            name
          }
          entity {
            id
            name
          }
          name
          description
          accountManager {
            id
            name
          }
          signed
          submitted
          validUntil
          status
          projects {
            id
            compactId
            name
            status
            product {
              id
              name
            }
          }
        }
        cursor
      }
    }
  }
`;

export const ListContractsWithProjectsComponent = (
  props: Omit<
    Urql.QueryProps<ListContractsWithProjectsQuery, ListContractsWithProjectsQueryVariables>,
    'query'
  > & { variables?: ListContractsWithProjectsQueryVariables },
) => <Urql.Query {...props} query={ListContractsWithProjectsDocument} />;

export function useListContractsWithProjectsQuery(
  options?: Omit<Urql.UseQueryArgs<ListContractsWithProjectsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListContractsWithProjectsQuery, ListContractsWithProjectsQueryVariables>({
    query: ListContractsWithProjectsDocument,
    ...options,
  });
}
export const ListContractsDocument = gql`
  query ListContracts(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $search: [String]
    $orderBy: String
    $entityId: String
    $clientId: String
    $clientIds: [String]
    $active: Boolean
    $statuses: [String]
  ) {
    contracts(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      search: $search
      orderBy: $orderBy
      entityId: $entityId
      clientId: $clientId
      clientIds: $clientIds
      active: $active
      statuses: $statuses
    ) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          client {
            id
            name
          }
          entity {
            id
            name
          }
          name
          description
          accountManager {
            id
            name
          }
          signed
          submitted
          validUntil
          status
        }
        cursor
      }
    }
  }
`;

export const ListContractsComponent = (
  props: Omit<Urql.QueryProps<ListContractsQuery, ListContractsQueryVariables>, 'query'> & {
    variables?: ListContractsQueryVariables;
  },
) => <Urql.Query {...props} query={ListContractsDocument} />;

export function useListContractsQuery(
  options?: Omit<Urql.UseQueryArgs<ListContractsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListContractsQuery, ListContractsQueryVariables>({
    query: ListContractsDocument,
    ...options,
  });
}
export const ListEntitiesDocument = gql`
  query ListEntities {
    entities {
      id
      compactId
      name
      manager {
        id
        name
      }
      managerRoleName
      layer
      parent {
        id
        name
        layer
      }
      regions {
        id
        compactId
        name
        manager {
          id
          name
        }
        managerRoleName
        layer
        parent {
          id
          name
          layer
        }
        hubs {
          id
          compactId
          name
          manager {
            id
            name
          }
          managerRoleName
          layer
          parent {
            id
            name
            layer
          }
        }
      }
      contractSet {
        edges {
          node {
            id
            compactId
            name
            accountManager {
              id
              name
            }
            status
          }
        }
      }
    }
  }
`;

export const ListEntitiesComponent = (
  props: Omit<Urql.QueryProps<ListEntitiesQuery, ListEntitiesQueryVariables>, 'query'> & {
    variables?: ListEntitiesQueryVariables;
  },
) => <Urql.Query {...props} query={ListEntitiesDocument} />;

export function useListEntitiesQuery(
  options?: Omit<Urql.UseQueryArgs<ListEntitiesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListEntitiesQuery, ListEntitiesQueryVariables>({
    query: ListEntitiesDocument,
    ...options,
  });
}
export const ListProductObjectivesDocument = gql`
  query ListProductObjectives(
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $productId: String
  ) {
    productObjectives(
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      productId: $productId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          shortName
          longName
        }
        cursor
      }
      pageCursors {
        around {
          cursor
          isCurrent
          page
        }
        first {
          cursor
          isCurrent
          page
        }
        last {
          cursor
          isCurrent
          page
        }
        next {
          cursor
          isCurrent
          page
        }
        previous {
          cursor
          isCurrent
          page
        }
        totalRecords
        totalPages
      }
    }
  }
`;

export const ListProductObjectivesComponent = (
  props: Omit<
    Urql.QueryProps<ListProductObjectivesQuery, ListProductObjectivesQueryVariables>,
    'query'
  > & { variables?: ListProductObjectivesQueryVariables },
) => <Urql.Query {...props} query={ListProductObjectivesDocument} />;

export function useListProductObjectivesQuery(
  options?: Omit<Urql.UseQueryArgs<ListProductObjectivesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListProductObjectivesQuery, ListProductObjectivesQueryVariables>({
    query: ListProductObjectivesDocument,
    ...options,
  });
}
export const ListProductsDocument = gql`
  query ListProducts(
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $regionId: String
    $clientId: String
    $clientIds: [String]
  ) {
    products(
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      regionId: $regionId
      clientId: $clientId
      clientIds: $clientIds
    ) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          realId
          name
          owner {
            id
            name
          }
        }
        cursor
      }
    }
  }
`;

export const ListProductsComponent = (
  props: Omit<Urql.QueryProps<ListProductsQuery, ListProductsQueryVariables>, 'query'> & {
    variables?: ListProductsQueryVariables;
  },
) => <Urql.Query {...props} query={ListProductsDocument} />;

export function useListProductsQuery(
  options?: Omit<Urql.UseQueryArgs<ListProductsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListProductsQuery, ListProductsQueryVariables>({
    query: ListProductsDocument,
    ...options,
  });
}
export const ListProfitCentresDocument = gql`
  query ListProfitCentres(
    $types: [ProfitCentreLayer]
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $parentId: String
  ) {
    profitCentres(
      types: $types
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      parentId: $parentId
    ) {
      edges {
        node {
          id
          name
          layer
          manager {
            id
            name
          }
          managerRoleName
          region {
            id
            name
          }
          entity {
            id
            name
          }
        }
        cursor
      }
      pageCursors {
        totalRecords
        totalPages
      }
    }
  }
`;

export const ListProfitCentresComponent = (
  props: Omit<Urql.QueryProps<ListProfitCentresQuery, ListProfitCentresQueryVariables>, 'query'> & {
    variables?: ListProfitCentresQueryVariables;
  },
) => <Urql.Query {...props} query={ListProfitCentresDocument} />;

export function useListProfitCentresQuery(
  options?: Omit<Urql.UseQueryArgs<ListProfitCentresQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListProfitCentresQuery, ListProfitCentresQueryVariables>({
    query: ListProfitCentresDocument,
    ...options,
  });
}
export const ListProjectsDocument = gql`
  query ListProjects(
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $productId: String
    $contractId: String
    $statuses: [String]
    $clientIds: [String]
  ) {
    projects(
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      productId: $productId
      contractId: $contractId
      statuses: $statuses
      clientIds: $clientIds
    ) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          compactId
          status
          contract {
            id
            name
            accountManager {
              id
              name
            }
          }
          product {
            id
            name
          }
          manager {
            id
            name
          }
          name
        }
        cursor
      }
    }
  }
`;

export const ListProjectsComponent = (
  props: Omit<Urql.QueryProps<ListProjectsQuery, ListProjectsQueryVariables>, 'query'> & {
    variables?: ListProjectsQueryVariables;
  },
) => <Urql.Query {...props} query={ListProjectsDocument} />;

export function useListProjectsQuery(
  options?: Omit<Urql.UseQueryArgs<ListProjectsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListProjectsQuery, ListProjectsQueryVariables>({
    query: ListProjectsDocument,
    ...options,
  });
}
export const ListRateCardsDocument = gql`
  query ListRateCards($offset: Int, $first: Int, $search: [String], $orderBy: String) {
    rateCards(offset: $offset, first: $first, search: $search, orderBy: $orderBy) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const ListRateCardsComponent = (
  props: Omit<Urql.QueryProps<ListRateCardsQuery, ListRateCardsQueryVariables>, 'query'> & {
    variables?: ListRateCardsQueryVariables;
  },
) => <Urql.Query {...props} query={ListRateCardsDocument} />;

export function useListRateCardsQuery(
  options?: Omit<Urql.UseQueryArgs<ListRateCardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListRateCardsQuery, ListRateCardsQueryVariables>({
    query: ListRateCardsDocument,
    ...options,
  });
}
export const ListRolesDocument = gql`
  query ListRoles($offset: Int, $first: Int, $search: [String], $orderBy: String) {
    roles(offset: $offset, first: $first, search: $search, orderBy: $orderBy) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const ListRolesComponent = (
  props: Omit<Urql.QueryProps<ListRolesQuery, ListRolesQueryVariables>, 'query'> & {
    variables?: ListRolesQueryVariables;
  },
) => <Urql.Query {...props} query={ListRolesDocument} />;

export function useListRolesQuery(
  options?: Omit<Urql.UseQueryArgs<ListRolesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListRolesQuery, ListRolesQueryVariables>({
    query: ListRolesDocument,
    ...options,
  });
}
export const ListUsersDocument = gql`
  query ListUsers(
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $hubId: String
    $hubIds: [String]
    $includeInactive: Boolean
  ) {
    users(
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      hubId: $hubId
      hubIds: $hubIds
      includeInactive: $includeInactive
    ) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          name
          inactive
          username
          projectCount
          roles {
            name
          }
          hub {
            id
            name
          }
        }
        cursor
      }
    }
  }
`;

export const ListUsersComponent = (
  props: Omit<Urql.QueryProps<ListUsersQuery, ListUsersQueryVariables>, 'query'> & {
    variables?: ListUsersQueryVariables;
  },
) => <Urql.Query {...props} query={ListUsersDocument} />;

export function useListUsersQuery(
  options?: Omit<Urql.UseQueryArgs<ListUsersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ListUsersQuery, ListUsersQueryVariables>({
    query: ListUsersDocument,
    ...options,
  });
}
export const SearchProjectsDocument = gql`
  query SearchProjects(
    $offset: Int
    $first: Int
    $search: [String]
    $orderBy: String
    $statuses: [String]
    $needDate: Boolean
    $startDate: Date
    $endDate: Date
  ) {
    projects(
      offset: $offset
      first: $first
      search: $search
      orderBy: $orderBy
      statuses: $statuses
      needDate: $needDate
      startDate: $startDate
      endDate: $endDate
    ) {
      pageCursors {
        totalRecords
        totalPages
      }
      edges {
        node {
          id
          name
          status
          startDate
          endDate
          budget {
            asString
            amount
            amountStr
            currency
          }
        }
        cursor
      }
    }
  }
`;

export const SearchProjectsComponent = (
  props: Omit<Urql.QueryProps<SearchProjectsQuery, SearchProjectsQueryVariables>, 'query'> & {
    variables?: SearchProjectsQueryVariables;
  },
) => <Urql.Query {...props} query={SearchProjectsDocument} />;

export function useSearchProjectsQuery(
  options?: Omit<Urql.UseQueryArgs<SearchProjectsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SearchProjectsQuery, SearchProjectsQueryVariables>({
    query: SearchProjectsDocument,
    ...options,
  });
}
